<template>
  <v-container
    id="google-maps"
    fluid
    tag="section"
    v-if = "usertype == 'admin'"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          title="Privacy Policy"
          class="px-5 py-3"
        >
<v-row>
      <v-col
    cols="12"
    md="12"
    >
    <client-only>
  <vue-editor v-model="AboutUS[0].Privacy" />
</client-only>

  </v-col>
    <v-btn 
    color="primary"
    dark
    class="mb-2"
    @click="updateabout"
    style="margin-top: 10px;">Save</v-btn>
</v-row>
        </base-material-card>
      </v-col>


      <v-col cols="12">
        <base-material-card
          color="success"
          title="About US"
          class="px-5 py-3"
        >
<v-row>
      <v-col
    cols="12"
    md="12"
    >
    <client-only>
  <vue-editor v-model="AboutUS[0].About_HTML" />
</client-only>

  </v-col>
    <v-btn 
    color="primary"
    dark
    class="mb-2"
    @click="updateabout"
    style="margin-top: 10px;">Save</v-btn>
</v-row>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
          color="success"
          title="Users & Permission"
          class="px-5 py-3"
        >
            <v-row>
           <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                  v-model="NewUser.UserName"
                    label="UserName"
                    class="purple-input"
                    outlined
                    dense
                  />
                </v-col>
                  <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    v-model="NewUser.Password"
                    label="Password"
                    type='password'
                    class="purple-input"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                <v-combobox
                  v-model="NewUser.Type"
                  :items="userpermission"
                  label="Permission"
                  outlined
                  dense
                ></v-combobox>
                </v-col>
        </v-row>

       
        <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="SaveNewUser"
              style="margin-top: 10px;"
            >
              Add New User
            </v-btn>
        
         <template>
                  <v-data-table
                    :headers="header"
                    :items="Users"
                    item-key="name"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                      >
                        <v-toolbar-title>UserList</v-toolbar-title>
                        <v-divider
                          class="mx-4"
                          inset
                          vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialog"
                          max-width="500px"
                        >
                          <v-card>

   <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="editedItem.UserName"
                                      label="UserName"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="editedItem.Password"
                                      label="Password"
                                      type='password'
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-combobox
                                      v-model="editedItem.Type"
                                      :items="userpermission"
                                      label="Permission"
                                      outlined
                                      dense
                                    ></v-combobox>
                                  </v-col>


                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="headline">Are you sure delete this item?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.Password="{ item }">
                      <v-text-field
                      v-model='item.Password'
                      disabled
                      outlined
                      dense
                      type='password'>

                      </v-text-field>
                    </template>
                  </v-data-table>
                </template>


        </base-material-card>
      </v-col>

<!--       <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="success"
          title="Incube Mobility Soluitons Information"
          class="px-5 py-3"
        >
     <row>
          <v-textarea
          label= 'About Us'  
          outlined
          dense
          v-model = 'CompanyInfo.AboutUs'>


          </v-textarea>
        </row>

        <row>
          <v-text-field 
          label= 'Mobile Phone'  
          outlined
          dense
          v-model = 'CompanyInfo.Phone'>

          </v-text-field>

        </row>

        <row>
            <v-toolbar-title>Images</v-toolbar-title>
          <div 
             
              v-for = "(imgs , index) in CompanyInfo.Images"
              :key="`t-${imgs}`"
              style="display: inline-block;">
            
              <v-icon  @click="ondelete(index)"> mdi-close</v-icon>
               <img 
                class="img" 
                :src="imgs"
                width="200" 
                height="200">
            </div>
        </row>

<row>
  <div>
         <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="onPickFile"
                >Upload Image
              <v-icon right dark>mdi-cloud-upload</v-icon>
             
               
              </v-btn>

  <input 
              type="file" 
              style="display: none" 
              ref="fileinput" 
              accept="image/*"
              @change="onfilepicked">

                   <v-btn
                    color="success"
                    class="mr-0"
                    @click="InsertItemToDB">
                    Save Information
                  </v-btn>
                </div>
</row>

        </base-material-card>
      </v-col> -->
    </v-row>

  </v-container>


  
</template>
<script>

import { db ,fb } from '@/db'
import {gmapApi} from 'vue2-google-maps'
import { VueEditor } from "vue2-editor";

  export default {
    name: 'DashboardIcons',
    components: {
    VueEditor
    },
    data: () => ({
      FinalImages :[],
       mapTypeId: "roadmap",
       editedItem: {
          UserName: '',
          Password: '',
          Type: ''
        },
        NewUser: {
          UserName: '',
          Password: '',
          Type: ''
        },
        editItem (item) {
          this.editedIndex = this.Users.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dialog = true
        },
       deleteItem (item) {
          this.editedIndex = this.Users.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dialogDelete = true
          let x = this.Users[editedIndex].id
        },
        userpermission:['hr' , 'admin'],
        CompanyInfo : '',
        Images : [],
        ImageFire : [] , 

 }),
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
   computed: {
     google: gmapApi,
      usertype(){
        return this.$store.state.userType
      },
      AboutUS(){
          return this.$store.state.Info  
      },
      Users(){
        return this.$store.state.Users
      },
      header () {
        return [
          {
            text: 'UserName ',
            align: 'start',
            value: 'UserName',
          },
          { text: 'Password', value: 'Password' },
          { text: 'Type', value: 'Type',  align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
      },
    },
    created(){
        this.$store.dispatch('binduser');
        this.$store.dispatch('bindInfo');

    },
    methods :{

    ondelete(index){
      this.CompanyInfo.Images.splice( index, 1 );

      },
      SaveNewUser(){
      db.collection('Users')
        .add(this.NewUser)
        .then(docRef => {
          this.NewUser = ''
          alert("Request has been send")
         
        })
      },
      editItem (item) {
        this.editedIndex = this.Users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
       deleteItem (item) {
        this.editedIndex = this.Users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        let x = this.Users[editedIndex].id
      },
      onPickFile(){
      this.$refs.fileinput.click();
    },
    onfilepicked(event){
      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0){
        return alert('please add a valid file !')
      }
 
        const reader = new FileReader()
        reader.addEventListener('load' , () => {
           this.Images.push(reader.result)
           this.CompanyInfo.Images.push(reader.result)
        })
        reader.readAsDataURL(files[0])
        this.ImageFire.push(files[0])
    },
    deleteItemConfirm () {
        
        
         db.collection('Users')
         .doc(this.Users[this.editedIndex].id)
         .delete()
        this.closeDelete()
      },

    close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      updateabout() {
  return db.collection('Information').doc('OurInformation')
    .update({
      "Privacy": this.AboutUS[0].Privacy,
      "About_HTML": this.AboutUS[0].About_HTML  
    })
    .then(_ => {  
      alert('Save Successfully');
    });
},
    InsertItemToDB(){
      var item  = this.CompanyInfo;
      var context = this; 
      
        db.collection('CompanyDefine')
        .doc('Information')
        .update( item, components)
        .then(docRef => {
          var Imagelist = new Array();
          var uuid = require("uuid");
          var x  = 0; var y = 0;

          for ( x = 0 ; x < context.CompanyInfo.Images.length ; x++){
            
            let KH = context.CompanyInfo.Images[x].split(":");
            if (context.CompanyInfo.Images[x].split(":") != 'data')
            {
              Imagelist[y] = context.CompanyInfo.Images[x];
              continue
            }
          var storageRef = fb.storage().ref('PostAdsPic/' + docRef.id +'/'+ uuid.v4())
          let UploadTask = storageRef.put(context.ImageFire[x]);

          UploadTask.on('state_changed' , async function(snapshot){
          }, function(error) {

          },function() {
            UploadTask.snapshot.ref.getDownloadURL().then( function(downloadURL){
            console.log("Photo num " + x + " Uploaded done : " + downloadURL)
            Imagelist[y] = downloadURL
            y = y+1
            if (y == context.ImageFire.length)
              {
                return db.collection('CompanyDefine').doc('Information')
                .update({"Images" : Imagelist})
                .then(_ => {  
                  this.form.snackbar = true
                  this.selected = null

                  })
              }
            })
          })
        }
    
        })
    },

    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    save () {
  db.collection('Users')
         .doc(this.Users[this.editedIndex].id)
         .update(this.editedItem)
        this.close()

      },
    }
}


</script>